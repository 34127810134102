/* Header-specific styles */
.menu-toggle-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-right: 0;
  transition: all 0.3s ease;
}

.menu-toggle-btn:hover {
  color: #eee;
}

.menu-toggle-btn .navbar-toggler-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-header {
  transition: all 0.3s ease;
  margin-left: 260px;
}

.logo-header {
  width: 260px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
  transition: all 0.3s ease;
  padding-left: 10px;
  padding-right: 10px;
}

/* Collapsed state styles */
.vertical-collpsed .main-header {
  margin-left: 70px;
}

.vertical-collpsed .logo-header {
  width: 70px;
}

.vertical-collpsed .logo-header .logo {
  display: none;
}

.vertical-collpsed .logo-header .navbar-toggler {
  margin: 0 auto;
}






.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo-header {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
}

.menu-toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  padding: 10px;
  transition: 0.3s ease;
}

.menu-toggle-btn:hover {
  color: #007bff;
}

.header-right {
  display: flex;
  align-items: center;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.profile-btn {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  padding: 5px;
  transition: 0.3s ease;
}

.profile-btn:hover {
  opacity: 0.8;
}

.avatar-sm img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
}

.dropdown-icon {
  color: #555;
  margin-left: 5px;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 50px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 200px;
  padding: 10px 0;
}

.user-box {
  text-align: center;
  padding: 15px;
}

.user-box .avatar-lg img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.u-text {
  margin-top: 10px;
}

.u-text h4 {
  font-size: 16px;
  font-weight: bold;
}

.u-text p {
  font-size: 12px;
  color: gray;
}

.dropdown-item {
  padding: 8px 15px;
  display: block;
  color: #333;
  transition: 0.3s;
}

.dropdown-item:hover {
  background: #f8f9fa;
}

.logout-btn {
  color: red;
}

