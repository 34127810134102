/* Sidebar-specific styles */
.sidebar-wrapper {
  width: 250px;
  transition: all 0.3s ease;
  height: 100%;
  overflow-y: auto;
}

.sidebar-wrapper.collapsed {
  width: 70px;
}

.vertical-collpsed .sidebar-wrapper {
  width: 70px;
}

/* Collapsed state styles */
.vertical-collpsed .sidebar-wrapper .user,
.vertical-collpsed .sidebar-wrapper .nav-section {
  display: none;
}

.vertical-collpsed .sidebar-wrapper p,
.vertical-collpsed .sidebar-wrapper .caret,
.vertical-collpsed .sidebar-wrapper .sub-item,
.vertical-collpsed .sidebar-wrapper .nav-collapse {
  display: none !important;
}

.vertical-collpsed .sidebar-wrapper .nav-item a {
  justify-content: center;
  padding: 10px 0;
}

.vertical-collpsed .sidebar-wrapper .nav-item i {
  margin: 0;
  font-size: 20px;
}

/* Main content margin adjustments */
.main-content {
  margin-left: 250px;
  transition: all 0.3s ease;
}

.vertical-collpsed .main-content {
  margin-left: 70px;
}

/* Sidebar enable state */
.sidebar-enable .sidebar-wrapper {
  width: 250px;
}

.sidebar-enable .main-content {
  margin-left: 250px;
}

/* Main container styles */
.main-container {
  position: relative;
  transition: all 0.3s ease;
} 

.main-container.vertical-collpsed .main-panel {
  position: relative;
  width: calc(100% - 250px);
  height: 100vh;
  min-height: 100%;
  float: right;
  transition: all .3s;
}
.logo-header{width: 250px;}

.sidebar .nav > .nav-item a i, .sidebar[data-background-color="white"] .nav > .nav-item a i
{
  margin: 0;
}
@media screen and (min-width: 991px) {
  .main-container.vertical-collpsed .main-panel {
      width: calc(100% - 75px);
      transition: all .3s;
  }
  .main-container.vertical-collpsed .sidebar {
      width: 70px;
  }
}
@media screen and (max-width: 991px) {

.main-container.vertical-collpsed .sidebar{
  left: 250px !important;
  /* left: 250px !important; */
  z-index: 999999;
  top: 60px;
  
}
.main-container .main-panel {
  width: calc(100% - 0px);
}
.main-container.vertical-collpsed .main-panel {
  width: calc(100% - 250px);
}
.main-container.vertical-collpsed .sidebar-wrapper {
  width: 250px;
}
.nav_open .main-panel, .nav_open .main-header {
  -webkit-transform: translate3d(250px, 0, 0);
  -moz-transform: translate3d(250px, 0, 0);
  -o-transform: translate3d(250px, 0, 0);
  -ms-transform: translate3d(250px, 0, 0);
  transform: translate3d(250px, 0, 0) !important;
}
.vertical-collpsed .sidebar-wrapper p, .vertical-collpsed .sidebar-wrapper .caret, .vertical-collpsed .sidebar-wrapper .sub-item, .vertical-collpsed .sidebar-wrapper .nav-collapse {
  display: inline-block !important;
  right: -60px; /* Adjust for collapsed state */

}
.footer {
  left: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  border-top: 1px solid #eee !important;
  padding: 15px !important;
  background: #ffffff !important;
  position: relative !important;
  text-align: center !important;
}
}





/* Logout Button */
.nav-item.logout {
  margin-top: 20px;
}

.logout-btn {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: background 0.3s ease;
}

.logout-btn i {
  margin-right: 10px;
}

.logout-btn:hover {
  background: #d9534f;
  color: white;
}
