.card {
  border-radius: 12px;
}

.card-body {
  padding: 20px;
}

.card h5 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #3a3f58;
}

button {
  transition: all 0.3s ease;
}

button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

li {
  transition: all 0.3s ease;
}



ul.list-unstyled {
  padding-left: 0;
}

.text-primary {
  color: #0056b3;
}

.text-success {
  color: #28a745;
}

.text-muted {
  color: #6c757d;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-white {
  background-color: #fff !important;
}

.shadow-lg {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rounded-lg {
  border-radius: 10px;
}


.vendor-info {
  flex: 1;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .vendor-info {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }
  
  .d-flex.justify-content-between {
    flex-direction: column;
  }
}
/* Custom Modal Width and Shadow */
.custom-modal-dialog {
  max-width: 90%; /* Increase width of modal */
  width: 70%; /* Set modal width to 80% of the screen */
}

.modal-content {
  border-radius: 12px; /* Rounded corners for the modal */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Deep shadow effect for 3D look */
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .custom-modal-dialog {
    max-width: 100%; /* Ensure modal width is 100% on smaller screens */
  }
}

/* Ensure rounded corners for modal */
.rounded-lg {
  border-radius: 12px;
}

/* Add a hover effect to buttons */
button {
  transition: all 0.3s ease;
}

button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
/* Card Container */
.image-card {
position: relative;
width: 100%;
max-width: 400px;
margin-bottom: 20px;
overflow: hidden;
border-radius: 8px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Image Styling */
.image-card img {
width: 100%;
height: auto;
transition: transform 0.3s ease;
border-radius: 8px;
}

/* Hover effect: Zoom in */
.image-card:hover img {
transform: scale(1.1);
}

/* Optional: Add a subtle overlay on hover */
.image-card:hover::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.3);
border-radius: 8px;
transition: all 0.3s ease;
}
/* Container for product and license images */
.image-row {
display: flex;
flex-wrap: wrap;
gap: 20px;  /* Adds spacing between the images */
justify-content: space-between;  /* Distribute space between items */
}

/* Adjust individual image-card styles */
.image-card {
position: relative;
width: calc(33% - 20px);  /* Ensure 3 items per row with spacing */
margin-bottom: 20px;
overflow: hidden;
border-radius: 8px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustment for smaller screens */
@media (max-width: 768px) {
.image-card {
  width: calc(50% - 10px);  /* 2 items per row on small screens */
}
}

@media (max-width: 480px) {
.image-card {
  width: 100%;  /* 1 item per row on very small screens */
}
}

/* Image styling inside the card */
.image-card img {
width: 100%;
height: auto;
transition: transform 0.3s ease;
border-radius: 8px;
}

/* Hover effect for zoom-in on image */
.image-card:hover img {
transform: scale(1.1);
}

/* Optional: Add a subtle overlay on hover */
.image-card:hover::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.3);
border-radius: 8px;
transition: all 0.3s ease;
}
/* Apply styles to the background overlay */
.background-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5); /* Darken the background */
z-index: 1040; /* Ensure it's behind the modal */
}

/* Optional: Add a blur effect to the background */
.background-overlay.blur {
backdrop-filter: blur(5px); /* Apply blur effect */
background-color: rgba(0, 0, 0, 0.3); /* Adjust darkness when blurred */
}


.tabless td, .table th {
font-size: 14px;
border-top-width: 0px;
border-bottom: 1px solid;
border-color: #ebedf2 !important;
padding: 5px 25px !important;
vertical-align: middle !important; }

/* Make the pagination buttons smaller */
.small-pagination .page-link {
padding: 0.25rem 0.5rem; /* Adjust padding */
font-size: 0.875rem; /* Smaller font size */
}

.small-pagination .page-item {
font-size: 0.875rem; /* Smaller font size for the page item */
}

.small-pagination .page-item.active .page-link {
padding: 0.25rem 0.5rem;
}

.small-pagination .page-link {
border-radius: 0.2rem; /* Optional: for rounded corners */
}






/* Ensures that the vendor list is scrollable if the list is long */
.vendor-list {
max-height: 300px; /* Set a max-height for the list */
overflow-y: auto;  /* Enable vertical scrolling if content exceeds max-height */
padding-right: 10px; /* Prevent the scrollbar from hiding content */
}

/* Optional: Style the scrollbars to be a bit more user-friendly */
.vendor-list::-webkit-scrollbar {
width: 8px; /* Adjust scrollbar width */
}

.vendor-list::-webkit-scrollbar-thumb {
background-color: rgba(0, 0, 0, 0.3); /* Scrollbar thumb color */
border-radius: 4px;
}

.vendor-list::-webkit-scrollbar-track {
background-color: #f1f1f1; /* Scrollbar track color */
}
