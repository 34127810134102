.footer {
  position: fixed;
  left: 260px;
  bottom: 0;
  width: calc(100% - 260px);
  background-color: #f8f9fa; 
  padding: 20px 0;
  text-align: center;
  z-index: 1000;
  transition: all 0.3s ease;
}

.footer-container {
  margin-left: 260px;
  transition: all 0.3s ease;
}

/* Add these styles if they don't exist */
.main-container {
  position: relative;
  transition: all 0.3s ease;
}

.sidebar-wrapper {
  width: 260px;
  transition: all 0.3s ease;
}

.sidebar-wrapper.collapsed {
  width: 70px;
}

.vertical-collpsed .sidebar-wrapper {
  width: 70px;
}

.sidebar-enable .sidebar-wrapper {
  width: 260px;
}

/* Update the menu toggle button styles */
.menu-toggle-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-right: 15px;
  transition: all 0.3s ease;
}

.menu-toggle-btn:hover {
  color: #eee;
}

.menu-toggle-btn .navbar-toggler-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-collpsed .footer {
  left: 70px;
  width: calc(100% - 70px);
}

.vertical-collpsed .footer-container {
  margin-left: 70px;
}
